/*
 * Dashforge Calendar Page
 *
 * This style is use for calendar page.
 *
 */
@media (max-width: 374px) {
  .app-calendar.calendar-sidebar-show .calendar-content {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .app-calendar.calendar-sidebar-show .calendar-sidebar {
    left: 0;
    visibility: visible;
  }
  .app-calendar.calendar-sidebar-show .calendar-content {
    transform: translateX(280px);
  }
}
.calendar-wrapper {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (min-width: 992px) {
  .calendar-wrapper {
    top: 80px;
  }
}
.calendar-sidebar {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 100%;
  border-right: 1px solid rgba(72, 94, 144, 0.16);
  visibility: hidden;
  transition: all 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .calendar-sidebar {
    transition: none;
  }
}
@media (min-width: 375px) {
  .calendar-sidebar {
    width: 280px;
    left: -281px;
  }
}
@media (min-width: 992px) {
  .calendar-sidebar {
    left: 0;
    visibility: visible;
  }
}
.calendar-sidebar-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  display: flex;
  align-items: center;
  padding: 0 15px 0 20px;
}
.calendar-sidebar-header .feather-search {
  color: #8392a5;
  align-self: center;
  width: 18px;
  stroke-width: 2.8px;
  margin-right: 10px;
  margin-top: -2px;
}
.calendar-sidebar-header .search-form {
  flex: 1;
  display: flex;
  align-items: center;
}
.calendar-sidebar-header .search-form .form-control {
  border-width: 0;
  background-color: transparent;
  font-size: inherit;
  padding: 0;
  color: #1b2e4b;
}
.calendar-sidebar-body {
  position: absolute;
  top: 55px;
  bottom: 0;
  left: 0;
  right: 0;
}
.calendar-nav {
  display: flex;
  flex-direction: column;
}
.calendar-nav a {
  display: flex;
  align-items: center;
  color: #1b2e4b;
  padding: 6px 10px;
  border-radius: 0.25rem;
}
.calendar-nav a:hover {
  background-color: #f5f6fa;
}
.calendar-nav a span {
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  margin-right: 10px;
  border: 2px solid transparent;
}
.calendar-nav a span::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  background-color: inherit;
  border-radius: 100%;
  display: none;
}
.calendar-nav a.calendar span {
  border-color: #0168fa;
}
.calendar-nav a.calendar span::before {
  background-color: #0168fa;
}
.calendar-nav a.birthday span {
  border-color: #10b759;
}
.calendar-nav a.birthday span::before {
  background-color: #10b759;
}
.calendar-nav a.holiday span {
  border-color: #f10075;
}
.calendar-nav a.holiday span::before {
  background-color: #f10075;
}
.calendar-nav a.discover span {
  border-color: #00cccc;
}
.calendar-nav a.discover span::before {
  background-color: #00cccc;
}
.calendar-nav a.meetup span {
  border-color: #5b47fb;
}
.calendar-nav a.meetup span::before {
  background-color: #5b47fb;
}
.calendar-nav a.other span {
  border-color: #fd7e14;
}
.calendar-nav a.other span::before {
  background-color: #fd7e14;
}
.calendar-nav a.show span::before {
  display: block;
}
.schedule-item {
  display: block;
  padding: 0 10px;
  color: #1b2e4b;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .schedule-item {
    transition: none;
  }
}
.schedule-item:hover,
.schedule-item:focus {
  color: #0168fa;
}
.schedule-item h6 {
  margin-bottom: 3px;
}
.schedule-item span {
  font-size: 12px;
  color: #8392a5;
  display: block;
}
.schedule-item + .schedule-item {
  margin-top: 20px;
}
.calendar-content {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .calendar-content {
    transition: none;
  }
}
@media (min-width: 992px) {
  .calendar-content {
    left: 280px;
  }
}
.fc-toolbar.fc-header-toolbar {
  height: 55px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  padding: 0 15px;
}
.fc-toolbar .fc-button-group button:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.fc-toolbar .fc-button-group button:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.fc-toolbar button {
  height: 32px;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(72, 94, 144, 0.16);
  color: rgba(27, 46, 75, 0.7);
  box-shadow: none;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: capitalize;
  outline: none;
  text-shadow: none;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .fc-toolbar button {
    transition: none;
  }
}
.fc-toolbar button:hover,
.fc-toolbar button:focus {
  border-color: #8392a5;
  color: #1b2e4b;
  background-color: #fff;
  position: relative;
  z-index: 5;
}
.fc-toolbar button:active {
  background-color: #f5f6fa;
}
.fc-toolbar button.fc-prev-button,
.fc-toolbar button.fc-next-button {
  padding: 0;
  width: 34px;
  justify-content: center;
}
.fc-toolbar button.fc-prev-button span,
.fc-toolbar button.fc-next-button span {
  margin: 0;
}
.fc-toolbar button.fc-state-active {
  background-color: #f5f6fa;
  border-color: #c0ccda;
  color: #1b2e4b;
}
.fc-toolbar button.fc-today-button {
  border-radius: 0.25rem;
  color: rgba(27, 46, 75, 0.7);
  background-color: #fff;
  display: none;
}
@media (min-width: 768px) {
  .fc-toolbar button.fc-today-button {
    display: block;
  }
}
.fc-toolbar button.fc-today-button:hover,
.fc-toolbar button.fc-today-button:focus {
  border-color: #8392a5;
  color: #1b2e4b;
}
.fc-toolbar button.fc-today-button:active {
  background-color: #f5f6fa;
}
.fc-toolbar button.fc-today-button.fc-state-disabled {
  border-color: rgba(72, 94, 144, 0.16);
  background-color: #f5f6fa;
  color: #8392a5;
  font-weight: 400;
  cursor: default;
}
.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  background-color: #f5f6fa;
  color: #8392a5;
  border-color: rgba(72, 94, 144, 0.16);
}
@media (max-width: 575px) {
  .fc-toolbar button.fc-prev-button,
  .fc-toolbar button.fc-next-button {
    width: 32px;
    height: 32px;
  }
  .fc-toolbar button.fc-today-button {
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 11px;
  }
  .fc-toolbar button.fc-month-button,
  .fc-toolbar button.fc-agendaWeek-button,
  .fc-toolbar button.fc-agendaDay-button,
  .fc-toolbar button.fc-listWeek-button,
  .fc-toolbar button.fc-listMonth-button {
    text-indent: -9999px;
    width: 32px;
    height: 32px;
    position: relative;
    color: transparent;
  }
  .fc-toolbar button.fc-month-button::before,
  .fc-toolbar button.fc-agendaWeek-button::before,
  .fc-toolbar button.fc-agendaDay-button::before,
  .fc-toolbar button.fc-listWeek-button::before,
  .fc-toolbar button.fc-listMonth-button::before {
    position: absolute;
    top: 6px;
    left: 10px;
    z-index: 100;
    display: inline-block;
    text-indent: 0;
    font-size: 12px;
    font-weight: 700;
    color: #1b2e4b;
  }
  .fc-toolbar button.fc-month-button::before {
    content: 'M';
  }
  .fc-toolbar button.fc-agendaWeek-button::before {
    content: 'W';
  }
  .fc-toolbar button.fc-agendaDay-button::before {
    content: 'D';
  }
  .fc-toolbar button.fc-listWeek-button::before,
  .fc-toolbar button.fc-listMonth-button::before {
    content: 'L';
    left: 11px;
  }
}
.fc-toolbar .fc-icon-left-single-arrow,
.fc-toolbar .fc-icon-right-single-arrow {
  top: 0;
}
.fc-toolbar .fc-icon-left-single-arrow::after,
.fc-toolbar .fc-icon-right-single-arrow::after {
  font-family: 'Ionicons';
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 992px) {
  .fc-toolbar .fc-icon-left-single-arrow::after,
  .fc-toolbar .fc-icon-right-single-arrow::after {
    line-height: 0.9;
  }
}
.fc-toolbar .fc-icon-left-single-arrow::after {
  content: '\F3CF';
}
.fc-toolbar .fc-icon-right-single-arrow::after {
  content: '\F3D1';
}
.fc-toolbar .fc-clear {
  display: none;
}
.fc-toolbar .fc-left {
  order: 1;
  float: none;
  display: flex;
  align-items: center;
}
.fc-toolbar .fc-center {
  display: flex;
  align-items: center;
  order: 2;
}
.fc-toolbar .fc-center h2 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
}
@media (min-width: 576px) {
  .fc-toolbar .fc-center h2 {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .fc-toolbar .fc-center h2 {
    font-size: 20px;
  }
}
.fc-toolbar .fc-right {
  float: none;
  order: 3;
  display: none;
}
@media (min-width: 480px) {
  .fc-toolbar .fc-right {
    display: flex;
    align-items: center;
  }
}
.fc-view-container {
  height: calc(100% - 50px);
}
.fc-head-container {
  border-top-width: 0;
  border-left-width: 0;
}
.fc-head-container .fc-day-header {
  padding: 5px 0;
  border-color: rgba(72, 94, 144, 0.16);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #1b2e4b;
}
.fc-widget-content {
  border-left-width: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
}
td {
  border-color: rgba(72, 94, 144, 0.16);
}
td.fc-today {
  background-color: rgba(244, 245, 248, 0.7);
}
td.fc-today .fc-day-number,
td.fc-today .fc-day-number:hover,
td.fc-today .fc-day-number:focus {
  background-color: #1439a8;
  color: #fff;
  border-radius: 1px;
}
.fc-other-month {
  background-color: #f8f9fc;
}
.fc-day-number {
  font-size: 14px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  color: #596882;
  display: inline-block;
  padding: 5px 8px;
  position: relative;
  transition: all 0.2s ease-in-out;
  margin: 2px 2px 0 0;
  min-width: 20px;
  text-align: center;
}
@media (prefers-reduced-motion: reduce) {
  .fc-day-number {
    transition: none;
  }
}
.fc-day-number:hover,
.fc-day-number:focus {
  color: #1b2e4b;
  background-color: #f5f6fa;
}
.fc-day-top.fc-other-month {
  color: #c0ccda;
  opacity: 1;
}
.fc-event {
  border-width: 0;
  border-top-width: 5px;
  border-radius: 0;
}
@media (min-width: 576px) {
  .fc-event {
    border-top-width: 0;
    border-left-width: 3px;
    padding: 3px 4px;
  }
}
.fc-event.fc-day-grid-event > div {
  display: none;
}
@media (min-width: 576px) {
  .fc-event.fc-day-grid-event > div {
    display: block;
  }
}
.fc-event.fc-day-grid-event > div .fc-time {
  font-weight: 500;
}
.fc-event.fc-day-grid-event > div .fc-desc {
  display: none;
}
.fc-time-grid-event {
  padding: 5px;
  border-left-width: 0;
  border-top-width: 2px;
}
.fc-time-grid-event .fc-time {
  font-size: 12px;
}
.fc-time-grid-event .fc-title {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
}
.fc-time-grid-event .fc-desc {
  line-height: 1.3;
  color: rgba(28, 39, 60, 0.8);
}
.fc-agendaWeek-view .fc-time-grid-event > div {
  display: none;
}
@media (min-width: 576px) {
  .fc-agendaWeek-view .fc-time-grid-event > div {
    display: block;
  }
}
.fc-agendaWeek-view .fc-time-grid-event > div .fc-time {
  font-size: 11px;
}
.fc-agendaWeek-view .fc-time-grid-event > div .fc-desc {
  display: none;
}
@media (min-width: 992px) {
  .fc-agendaWeek-view .fc-time-grid-event > div .fc-desc {
    display: block;
  }
}
.fc-list-empty {
  padding: 20px 0;
  background-color: #f8f9fc;
}
.fc-agenda-view .fc-day-header > a > span {
  display: block;
}
.fc-agenda-view .fc-day-header > a > span:first-child {
  color: #8392a5;
  font-size: 8px;
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 576px) {
  .fc-agenda-view .fc-day-header > a > span:first-child {
    font-size: 11px;
    font-weight: 500;
  }
}
.fc-agenda-view .fc-day-header > a > span:last-child {
  font-weight: 500;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1;
}
@media (min-width: 576px) {
  .fc-agenda-view .fc-day-header > a > span:last-child {
    font-size: 25px;
  }
}
.fc-agenda-view .fc-day-header.fc-today > a {
  color: #0168fa;
}
.fc-listMonth-view,
.fc-listWeek-view {
  border-width: 0;
}
.fc-listMonth-view .fc-scroller,
.fc-listWeek-view .fc-scroller {
  padding: 20px 20px 20px 0;
}
.fc-listMonth-view .fc-list-table,
.fc-listWeek-view .fc-list-table {
  border-collapse: separate;
  display: block;
}
.fc-listMonth-view .fc-list-table > tbody,
.fc-listWeek-view .fc-list-table > tbody {
  display: flex;
  flex-wrap: wrap;
}
.fc-listMonth-view .fc-list-heading,
.fc-listWeek-view .fc-list-heading {
  flex: 0 0 20%;
  max-width: 20%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .fc-listMonth-view .fc-list-heading,
  .fc-listWeek-view .fc-list-heading {
    flex: 0 0 15%;
    max-width: 15%;
  }
}
@media (min-width: 768px) {
  .fc-listMonth-view .fc-list-heading,
  .fc-listWeek-view .fc-list-heading {
    flex: 0 0 12%;
    max-width: 12%;
  }
}
@media (min-width: 992px) {
  .fc-listMonth-view .fc-list-heading,
  .fc-listWeek-view .fc-list-heading {
    flex: 0 0 10%;
    max-width: 10%;
  }
}
@media (min-width: 1200px) {
  .fc-listMonth-view .fc-list-heading,
  .fc-listWeek-view .fc-list-heading {
    flex: 0 0 8%;
    max-width: 8%;
  }
}
.fc-listMonth-view .fc-list-heading:first-child,
.fc-listWeek-view .fc-list-heading:first-child {
  margin-top: 0;
}
.fc-listMonth-view .fc-list-heading:first-child + .fc-list-item,
.fc-listWeek-view .fc-list-heading:first-child + .fc-list-item {
  margin-top: 0;
}
.fc-listMonth-view .fc-list-heading td,
.fc-listWeek-view .fc-list-heading td {
  background-color: transparent;
  border-width: 0;
  padding: 0;
}
.fc-listMonth-view .fc-list-heading-main,
.fc-listWeek-view .fc-list-heading-main {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  color: #8392a5;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}
.fc-listMonth-view .fc-list-heading-main span:last-child,
.fc-listWeek-view .fc-list-heading-main span:last-child {
  display: block;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -1.5px;
  color: #001737;
  line-height: 1;
}
@media (min-width: 576px) {
  .fc-listMonth-view .fc-list-heading-main span:last-child,
  .fc-listWeek-view .fc-list-heading-main span:last-child {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .fc-listMonth-view .fc-list-heading-main span:last-child,
  .fc-listWeek-view .fc-list-heading-main span:last-child {
    font-size: 36px;
  }
}
.fc-listMonth-view .fc-list-heading-main.now,
.fc-listWeek-view .fc-list-heading-main.now {
  color: #0168fa;
}
.fc-listMonth-view .fc-list-heading-main.now span:last-child,
.fc-listWeek-view .fc-list-heading-main.now span:last-child {
  color: #0168fa;
}
.fc-listMonth-view .fc-list-item,
.fc-listWeek-view .fc-list-item {
  flex: 0 0 calc(80% - 5px);
  max-width: calc(80% - 5px);
  dispLay: flex;
  flex-direction: column;
  border-left: 4px solid transparent;
  background-color: #fff;
  margin-top: 15px;
}
@media (min-width: 576px) {
  .fc-listMonth-view .fc-list-item,
  .fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(85% - 5px);
    max-width: calc(85% - 5px);
  }
}
@media (min-width: 768px) {
  .fc-listMonth-view .fc-list-item,
  .fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(88% - 5px);
    max-width: calc(88% - 5px);
  }
}
@media (min-width: 992px) {
  .fc-listMonth-view .fc-list-item,
  .fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(90% - 5px);
    max-width: calc(90% - 5px);
  }
}
@media (min-width: 1200px) {
  .fc-listMonth-view .fc-list-item,
  .fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(92% - 5px);
    max-width: calc(92% - 5px);
  }
}
.fc-listMonth-view .fc-list-item:hover,
.fc-listMonth-view .fc-list-item:focus,
.fc-listWeek-view .fc-list-item:hover,
.fc-listWeek-view .fc-list-item:focus {
  cursor: pointer;
}
.fc-listMonth-view .fc-list-item:hover td,
.fc-listMonth-view .fc-list-item:focus td,
.fc-listWeek-view .fc-list-item:hover td,
.fc-listWeek-view .fc-list-item:focus td {
  background-color: transparent;
}
.fc-listMonth-view .fc-list-item > td,
.fc-listWeek-view .fc-list-item > td {
  border-width: 0;
}
.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
.fc-listWeek-view .fc-list-item > td.fc-list-item-time {
  border-top: 1px solid #e5e9f2;
  border-right: 1px solid #e5e9f2;
  box-sizing: border-box;
}
.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
  border-bottom: 1px solid #e5e9f2;
  border-right: 1px solid #e5e9f2;
  box-sizing: border-box;
}
.fc-listMonth-view .fc-list-item + .fc-list-item,
.fc-listWeek-view .fc-list-item + .fc-list-item {
  margin-left: 20%;
}
@media (min-width: 576px) {
  .fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 15%;
  }
}
@media (min-width: 768px) {
  .fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 12%;
  }
}
@media (min-width: 992px) {
  .fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 10%;
  }
}
@media (min-width: 1200px) {
  .fc-listMonth-view .fc-list-item + .fc-list-item,
  .fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 8%;
  }
}
.fc-listMonth-view .fc-list-item-marker,
.fc-listWeek-view .fc-list-item-marker {
  display: none;
}
.fc-listMonth-view .fc-list-item-time,
.fc-listWeek-view .fc-list-item-time {
  padding: 12px 15px 2px;
  width: 100%;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  letter-spacing: 0.5px;
}
.fc-listMonth-view .fc-list-item-title,
.fc-listWeek-view .fc-list-item-title {
  padding: 0 15px 12px;
}
.fc-listMonth-view .fc-list-item-title a,
.fc-listWeek-view .fc-list-item-title a {
  display: block;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  color: #001737;
}
@media (min-width: 576px) {
  .fc-listMonth-view .fc-list-item-title a,
  .fc-listWeek-view .fc-list-item-title a {
    font-size: 18px;
  }
}
.fc-listMonth-view .fc-list-item-title .fc-desc,
.fc-listWeek-view .fc-list-item-title .fc-desc {
  font-size: 12px;
  line-height: 1.375;
  display: block;
  color: #8392a5;
}
@media (min-width: 992px) {
  .fc-listMonth-view .fc-list-item-title .fc-desc,
  .fc-listWeek-view .fc-list-item-title .fc-desc {
    font-size: 13px;
  }
}
.fc-head-container .fc-day-header {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 0;
  color: #1b2e4b;
}
@media (min-width: 576px) {
  .fc-head-container .fc-day-header {
    font-size: 12px;
  }
}
.fc-axis {
  font-size: 11px;
}
@media (min-width: 576px) {
  .fc-axis {
    font-size: 12px;
  }
}
.calendar-modal-event .modal-content {
  border-width: 0;
  position: relative;
  background-color: transparent;
}
.calendar-modal-event .modal-header {
  position: relative;
  padding: 10px 15px 20px 20px;
  border-bottom-width: 0;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
@media (min-width: 576px) {
  .calendar-modal-event .modal-header {
    padding: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.calendar-modal-event .nav-modal-event {
  align-items: center;
  align-self: flex-end;
}
@media (min-width: 576px) {
  .calendar-modal-event .nav-modal-event {
    align-self: auto;
    order: 2;
  }
}
.calendar-modal-event .nav-modal-event .nav-link {
  padding: 0;
  color: rgba(255, 255, 255, 0.75);
  line-height: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .calendar-modal-event .nav-modal-event .nav-link {
    transition: none;
  }
}
.calendar-modal-event .nav-modal-event .nav-link svg {
  width: 16px;
}
@media (min-width: 992px) {
  .calendar-modal-event .nav-modal-event .nav-link svg {
    width: 20px;
    stroke-width: 2.5px;
  }
}
.calendar-modal-event .nav-modal-event .nav-link:hover,
.calendar-modal-event .nav-modal-event .nav-link:focus {
  color: #fff;
}
.calendar-modal-event .nav-modal-event .nav-link + .nav-link {
  margin-left: 10px;
}
.calendar-modal-event .event-title {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 0;
  line-height: 1;
  order: 2;
}
@media (min-width: 576px) {
  .calendar-modal-event .event-title {
    margin-top: 0;
    order: 1;
  }
}
@media (min-width: 992px) {
  .calendar-modal-event .event-title {
    font-size: 20px;
  }
}
.calendar-modal-event .modal-body {
  padding: 20px;
  background-color: #fff;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
@media (min-width: 576px) {
  .calendar-modal-event .modal-body {
    padding: 25px;
  }
}
.calendar-modal-event .event-start-date,
.calendar-modal-event .event-end-date {
  color: #1c273c;
  font-weight: 500;
}
.calendar-modal-event .event-desc {
  margin-top: 5px;
}
.calendar-modal-event .event-desc:empty {
  margin-bottom: 5px;
}
.fc-button:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
